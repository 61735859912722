.privacy-container {
  max-width: 800px;
  margin: auto;
  padding: 20px;
  font-family: "Arial, sans-serif";
}

.privacy-heading {
  color: #333;
  border-bottom: 2px solid #333;
  padding-bottom: 10px;
}

.contact-link {
  color: #007bff;
  text-decoration: none;
}
