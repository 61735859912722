.ProductList{
    position: absolute;
    left: 342px;
    top: 114px;
}
.contain{
    position: relative; 
}
.ProductListContain{
    margin: 34px;
    width: 1100px;
}
.ProductListTop{
    display: flex;
    
    justify-content: space-between;
    border-bottom: 1px solid gray;
    padding-bottom: 15px;
    

}
.ProductListTop1{
    display: flex;
    gap: 15px;
    align-items: center;

}
.ProductListTop1 > p:nth-child(2){
    background-color: rgb(112, 230, 112);

color: white;
padding: 4px 6px;
border-radius: 4px;
}
.ProductListTop1 > p:nth-child(3){
    background-color: rgb(220, 78, 78);
    
    color: white;
    padding: 4px 6px;
    border-radius: 4px;
 }
 .ProductListTop1 > p:nth-child(4){
    background-color: rgb(220, 203, 72);
   
    color: white;
    padding: 4px 6px;
    border-radius: 4px;
    }
  
.ProductListTop2{
    display: flex;
    gap: 15px;


}
.ProductListTop2 > select:nth-child(1){
    width: 110px;
    padding: 6px;
}
.ProductListTop2 > select:nth-child(2){
    width: 170px;
    padding: 6px;
}
.ProductTitleContain{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
   
}
.ProductListTableContain{
    position: relative;
    height:1400px;
    border: 1px solid gray;
}
.ProductPromt{
   
}
.ProductTitleLeft{
    display: flex;
    gap: 9px;
    align-items: center;


}
.ProductTitleLeft > select {
    width: 200px;
    padding: 9px;
}
.ProductTitleLeft > p:nth-child(3){
    background: gray;
    padding: 4px 9px;
    
   
    border-radius: 50%;
    
}
.TableProduct{
    display: flex;

    gap: 4px;
    align-items: center;
}
.ProductPromt{
    position: absolute;
   top: 10%;
    left: 50%;
    transform: translate(-50%,-10%);
    background-color: #242424;
    z-index: 100;
    
    color: white;
    padding: 10px;
    border-radius: 10px;
    
}
.ProductPromt > p {margin-bottom: 60px;}
.ProductPromtButton{
    display: flex;
    justify-content: space-around ;

}
.ProductPromtButton > button{
    border: 1px solid white;
    outline: none;
    padding: 8px 10px;
    background-color: transparent;
    color: white;
    border-radius: 9px;
    cursor: pointer;

}