.Products{
    position: absolute;
    left: 342px;
    top: 114px;
}
.Productscontain{
    position: relative;
   
    
   
}
.ProductsTitle{
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;

}
.ProductsInput1{
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
   justify-content: center;
  position: relative;
  

   
}
.iconDown1{
    position: absolute;
    right: 50px;
    bottom: 15px;

}

 
.ProductsInput1 > input{
    padding: 15px ;
    width: 570px;
    margin-right: 30px;
}
.ProductsInput1 > select{
    padding: 15px ;
    width: 570px;
    margin-right: 30px;
    appearance: none;
    -webkit-appearance: none;
    
    
}
.ProductsInput2 > input{
    padding: 15px ;
    width: 270px;
    margin-right: 30px;
    margin-bottom: 30px;
}
.ProductsInput2{
    position: relative;
  
}
.iconDown2{
    position: absolute;
    right: 70px;
    bottom: 45px;
   
}

.ProductsInput2 > select{
    padding: 15px ;
    width: 270px;
    margin-right: 30px;
    appearance: none;
    -webkit-appearance: none;
}
.ProductsInput3 > input{
    display: flex;
    flex-direction: column;
    padding: 15px ;
    width: 270px;
    margin-right: 30px;
    margin-bottom: 30px;


}
.ProductsInput4 > select{
    display: flex;
    flex-direction: column;
    
    width: 1140px;
   padding: 15px;
    margin-bottom: 30px;




}
.ProductsInput5 > select{
    display: flex;
    flex-direction: column;
    
    width: 1170px;
   padding: 15px;
    margin-bottom: 30px;




}
.ProductsInput6 > select{
    display: flex;
    flex-direction: column;
    padding: 16px;
    appearance: none;
    -webkit-appearance: none;




}
.iconDown{
    position: absolute;
    right: 25px;
   bottom: 15px;
}
.ProductsInput6 > input{
    padding: 14px;
    margin-right: 10px;

}
.ProductsInput6{
    display: flex;
    flex-direction: column;
    justify-content: center;
  position: relative;
    width: 390px;
  margin-bottom: 32px;
   
   




}
.ProductInputs{
    display: flex;
    max-width: 1256px;

   
    

}
.ProductsAdd{
    margin: 30px;
    

}
.ProductsInputLabel{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    gap: 10px;
}
.attribute{
    border: 1px solid gray;
    border-radius: 6px;
    padding: 14px;
    margin-bottom: 30px;
}
textarea{
    width: 1170px;
    height: 80px;
}
.custom-file-wrp{
	display: flex;
	width: 1170px;
    margin-top: 10px;
}

.filename{
	
	padding: 15px 15px;
	border: 1px solid #ddd;
	width: 1170px;
	height: 50px;
	
}

.custom-file input[type="file"]
{
	display: none
}

.custom-file label{
	cursor: pointer;
	
	display: inline-block;
	border: 1px solid #ddd;
	padding:  5px 65px;
	font-size: small;
    height: 50px;

}
.ProductButton{
    background-color: dodgerblue;
    color: white;
    padding: 15px 18px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}





.custom-file3 input[type="file"]
{
	display: none
}

.custom-file3 label{
	cursor: pointer;
	
	display: inline-block;
	border: 1px solid #ddd;
	padding:  5px 65px;
	font-size: small;
    height: 50px;

}
.custom-file-wrp3{
	display: flex;
	width: 1170px;
    margin-top: 10px;
}

.filename3{
	
	padding: 15px 15px;
	border: 1px solid #ddd;
	width: 1170px;
	height: 50px;
	
}







.custom-file4 input[type="file"]
{
	display: none
}

.custom-file4 label{
	cursor: pointer;
	
	display: inline-block;
	border: 1px solid #ddd;
	padding:  5px 65px;
	font-size: small;
    height: 50px;

}
.custom-file-wrp4{
	display: flex;
	width: 1170px;
    margin-top: 10px;
}

.filename4{
	
	padding: 15px 15px;
	border: 1px solid #ddd;
	width: 1170px;
	height: 50px;
	
}
.ShowInfo{
   
        display: flex;
        align-items: center;
        gap: 15px;
        background-color: rgb(78, 188, 72);
        color: white;
        padding: 15px 25px;
      
        border-radius: 5px;
        margin-top: 30px;
        width: fit-content;
      
      


}
.ShowInfoError{
    display: flex;
    align-items: center;
    gap: 15px;
    background-color: rgb(224, 80, 80);
    color: white;
    padding: 15px 25px;
    margin-bottom: 15px;
    border-radius: 5px;
    margin-top: 30px;
    width: fit-content;
}