.OrderDetailPage {
  position: absolute;
  left: 342px;
  top: 114px;
}
.contain {
  position: relative;
}
.OrderDetailContain {
  margin: 30px;
  display: flex;
  gap: 25px;
}
.OrderDetailBox {
  border: 1px solid gray;
  border-radius: 8px;

  width: 800px;
}
.OrderDetailBox > h1 {
  margin-bottom: 20px;
  border-bottom: 1px solid gray;
  padding: 25px;
}
.OrderDetailContain1 {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid gray;

  width: 100%;
  padding: 25px;
}
.OrderDetailContain1 > div {
  color: gray;
}
.OrderDetailContain2 {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgb(208, 206, 206);

  width: 100%;
  padding: 25px;
}
.OrderDetailImage {
  display: flex;
}
.OrderDetailImage > img {
  height: 40px;
}
.OrderDetailPrice {
  display: flex;

  justify-content: space-between;
}
.MainPricedata {
  display: flex;
  gap: 10px;
  margin: 20px;
}
.OrderDetailPriceItem {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.OrderDetailPriceItemName {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-right: 20px;
}
.OrderDetailPriceItem > p:nth-child(2) {
  color: gray;
}
.OrderCustomer {
  border: 1px solid gray;
  height: fit-content;
  width: 300px;
  border-radius: 10px;
}
.movec {
  position: absolute;
  left: 450px;
  top: 100px;
}
.OrderLocation {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
  margin-left: 15px;
}
.bank {
  bottom: 15px;
  gap: 10px;
  top: 15px;
}
