.Coupons{
    position: absolute;
    left: 342px;
    top: 114px;
}
.contain{
    position: relative; 
}
.CouponsContain{
    margin: 32px 12px;
   

    
}
.CouponPromt > p {margin-bottom: 60px;}
.CouponPromt{
    position: absolute;
   top: 35%;
    left: 50%;
    transform: translate(-50%,-10%);
    background-color: #242424;
    z-index: 100;
    
    color: white;
    padding: 10px;
    border-radius: 10px;
  
    
}
.CouponInputs{
    border: 1px solid #ddd;
 
  width: 1200px;
  margin: 0 auto;

    border-radius: 25px;
}
.CouponsInputContain{
    display: flex;
    align-items: center;
    gap: 25px;
    margin: 25px;

}
.CouponsInput{
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
}
.CouponsInput > input{
width: 100%;
padding: 12px ;
}
.CouponsInputDate{
    display: flex;
    flex-direction: column;
    width: 318px;
    
    }
 .CouponsInputDate > input{
    padding: 12px ;
 }   
.CouponsInput > select {
    width: 100%;
    padding: 12px ;
    appearance: none;
    -webkit-appearance: none;
}
.CouponRadioContain{
    display: flex;

    align-items: center;
    gap: 10px;

}
.CouponRadioContain > input{
   
  accent-color: #0000ff;
}
.couponButton{
    outline: none;
    border: none;
    padding: 10px 12px;
    color: white;
    background-color: dodgerblue;
    border-radius: 5px;
    cursor: pointer;
}
.CouponsPageTableHeader{
    display: flex;
    justify-content: space-between;
  margin: 15px;
}
.CouponNum{
    display: flex;
    align-items: center;
    gap: 10px;
}
.CouponNum > p:nth-child(2){
    background-color: rgb(202, 202, 202);
    padding: 5px 8px;
    border-radius: 5px;
    
}
.CouponsPageTable{
    border-radius: 25px;
    border: 1px solid #ddd;
    margin-top: 10px;
    height: 1400px;

}
.CoupontableContain{
    height: 550px;
}
.CouponIconDown{
position: absolute;
right: 20px;
bottom: 12px;
}