.Home{
    display: flex;
    flex-direction: column;
 align-items: center;



   
}


.HomeContain{
    position: absolute;
  left: 424px;
  right: 74px;
    top: 203px;
    
   
   
    
   
  
    
 
    
    

}
.orders{
  
    display: flex;
   

    border-radius:16px ;
    
height: 138px;
border: 2px solid #F3F4F6;


box-shadow: 2px 4px 24px rgba(0, 0, 0, 0.05);

align-items: center;



    
}
@media (max-width:862px) {
    .orders{
        flex-direction:column;
        height: fit-content;
        padding: 15px 0;
    }
    .order:nth-child(2){
        border-top: #BEBEBE 1px solid;
        border-bottom: #BEBEBE 1px solid;
        margin: 20px 0;
        border-left: none !important;
        border-right: none !important; 
        padding: 10px 0;
    }
    .statuses{
        flex-direction:column;
        gap: 10px;

    }
    .peopleContain{
        flex-direction:column;
        gap: 10px;
        max-width: 200px;
        
    }
    .HomeData{
        flex-direction:column;
        gap: 10px;
    }
   
}

.order > p{
    color: #4B5563;
    font-weight: 600;
font-size: 14px;
margin-bottom: 8px;
}
.order > h2{
    margin-top: 16px;
    
}
.order:nth-child(2){
    border-left: #BEBEBE 1px solid;
    border-right: #BEBEBE 1px solid;
}
.order{
    display: flex;
    flex-direction: column;
    width: 350px;
    align-items: center;
}

.trend{
  
   display: flex;
   gap: 10px;
  
}
.trend> p{
color: #22C55E;

}
.statuses{
    display: flex;
    align-items: center;
   margin-top: 79px;
  gap: 200px;  
    
}
.status{
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 24px;
border: 1px solid #F3F4F6;
box-shadow: 2px 4px 24px rgba(0, 0, 0, 0.05);
border-radius: 16px;
padding: 24px 58px;


}
.status:nth-child(1){
    background: #EABABA;



}

.status:nth-child(2){
    background: #EBE07D;
    



}
.status:nth-child(3){
    background:  #67C4A7;
    color: white;



}
.peopleContain{
margin-top: 67px;
    display: flex;
    gap: 40px;
   
   
    justify-content: center;

}
.peopleBox{
  
   
    border: #F3F4F6 1px solid;
box-shadow: 2px 4px 24px rgba(0, 0, 0, 0.05);
border-radius: 16px;
width: 500px;
position: relative;

}
.homeloader{
    position: absolute;
    top: 50%;
    left: 50%;
   transform: translate(-50%,-50%);
}
.peopleBoxheader{
    display: flex;
    justify-content: space-between;
    margin: 20px 10px 23px 10px;
}
.peopleBoxTitle{
    display: flex;
   
justify-content: space-between;

    color: gray;
    margin: 0 10px 0 10px;
    border-bottom: 1px solid #F3F4F6;
    padding-bottom: 5px;
    
}
.peopleBox2Title{
    display: flex;
   


    color: gray;
    margin: 0 10px 0 10px;
    border-bottom: 1px solid #F3F4F6;
    padding-bottom: 5px;
    
}
.peopleBox2Title > p:nth-child(2){
margin: 0 65px 0 120px;
}
.peopleBoxRowItems > img{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 9px;
}
.peopleBoxRowItems{
    display: flex;
    align-items: center;
}

.peopleBoxRow{
    display: flex;
justify-content: space-between;
    margin: 20px 15px;
    align-items: center;
}
.peopleBoxTitles{
    display: flex;
    gap: 60px;
}
.peopleBoxTitless{
    display: flex;



}
.peopleBoxTitless > p:nth-child(3){
    margin-right: 50px;
}
.peopleBoxTitless > p:nth-child(1){
    margin-right: 60px;
}
.peopleBoxTitless > p:nth-child(2){
    margin-right: 85px;
}

.gold{
    color: #Ffd700;
}
.HomeData{
  margin-top: 83px;
display: flex;
gap: 20px;
}
.HomeDataBox1{
   
    width: 326px;
    border: #F3F4F6 1px solid;
    box-shadow: 2px 4px 24px rgba(0, 0, 0, 0.05);
    border-radius: 16px;
    margin-bottom: 20px;
    height: 380px;
}
.CustomerRow{
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;

}
.HomeDataBox1:nth-child(2){
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;

}
.HomeDataBox1:nth-child(3){
    display: flex;
    flex-direction: column;
    
    
    padding: 20px;

}
.pie{
    margin: 0 auto;
  height: 250px;
  position: relative;
  
}
.pie > p{
    position: absolute;
    font-weight: 600;
    font-size: 24px;
line-height: 32px;
color: #1F2937;
top: 45%;
left: 40%;


}