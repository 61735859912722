.Banner{
    position: absolute;
    left: 342px;
    top: 114px;
}

.contain{
    position: relative;

    
   
}
.BannerContain{
    margin: 32px;
}
.BannerBox{
    border: 1px solid #ddd;
    display: flex;
   
    width: 1200px;
    margin-bottom: 32px;
    
}
.BannerBox2{
    border: 1px solid #ddd;
    
    height: 1400px;
   
    width: 1200px;
   
    position: relative;
    
}
.BannerBoxInputs{
    padding: 20px;
 width: 50%;
 
}
.BannerBoxInput{
    margin-bottom: 20px;
    position: relative;
}
.BannerBoxInput > select{
    width: 100%;
    padding: 16px;
    margin-top: 9px;
    appearance: none;
    -webkit-appearance: none;

}
.BannerBoxInput > input{
    width: 100%;
    padding: 16px;
    margin-top: 9px;
}
.filename1{
  	
	padding: 15px 15px;
	border: 1px solid gray;
	width: calc(50% - 196px);
	height: 50px;
}
.BannerinputImage{
    margin-top: 20px;
    border-radius: 10px;
  width: 100%;
   

}
.Bannerbutton{
    border-radius: 8px;
    padding: 10px;
    margin-top: 42px;
    margin-bottom: 10px;
    border: none;
    background-color: rgb(87, 137, 255);
    color: white;
    cursor: pointer;
}
.BannertitleContain{
    display: flex;
    justify-content: space-between;
    padding: 24px;
}
.Bannertitle{
    display: flex;
    gap: 10px;
    align-items: center;
}
.Bannertitle > p:nth-child(2){
    background-color: rgb(198, 198, 198);
    padding: 4px 8px;
    border-radius: 5px;
}
.Bannertitle2{
    display: flex;
    align-items: center;


}
.Bannertitle2 > input {
    border: none;
    outline: none;
    border-bottom: 1px solid gray;
    padding-bottom: 12px;
}
.Bannertitle2 > button {
    padding: 6px 5px;
    background-color: #ddd;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.bannerIcon{
    border-bottom: 1px solid gray;
    color: gray;
    width: 29px;
    height: 29px;
    padding-bottom: 12px;



}
.BannertableImage{
    
    border-radius: 7px;
    height: 40px;
    
    object-fit: contain;
}
.BannerTableContain{
    margin-bottom: 92px;
}
.BannericonDown{
position: absolute;
bottom: 15px;
right: 30px;
}
.bannerTypeList{
    display: flex;
    justify-content: space-between;

    margin-bottom: 10px;


}
.BannerTypeContain{
  
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 5px;

}
.bannerTypeList > button {
    padding: 10px 13px;
    border-radius: 5px;
    cursor: pointer;
    border:1px solid black;

    background-color: transparent;

}
.BannerPromt{
    position: absolute;
   top: 10%;
    left: 50%;
    transform: translate(-50%,-10%);
    background-color: #242424;
    z-index: 100;
    
    color: white;
    padding: 10px;
    border-radius: 10px;
    
}
.BannerPromt > p {margin-bottom: 60px;}
.BannerPromtButton{
    display: flex;
    justify-content: space-around ;
}
.BannerPromtButton > button{
    border: 1px solid white;
    outline: none;
    padding: 8px 10px;
    background-color: transparent;
    color: white;
    border-radius: 9px;
    cursor: pointer;

}
