.loginContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url("./assets/495\ 1.jpg");

  width: 100vw;
  height: 100vh;
  background-size: 100%;
}

.image {
  mix-blend-mode: darken;
  width: 200px;
  margin-bottom: 5px;
}

.loginForm {
  display: flex;
  align-items: center;
  flex-direction: column;

  background-color: #ffffff;
  border: 1px solid #dfe0eb;
  border-radius: 8px;
  position: relative;
  padding: 37px 57px 37px 57px;
}

.loginSubTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 20px;
  margin-bottom: 15px;
  color: #000000;
}

.loginDetails {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #000000;
  margin-bottom: 65px;
}

.formContain {
  display: flex;
  flex-direction: column;
}

label {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 40px;
  margin-bottom: 8px;

  color: #000000;
}

.formContain > input {
  margin-bottom: 24px;
  width: 356px;
  height: 42px;
  background: #fcfdfe;
  padding: 25px 20px;

  border: 1px solid #f0f1f7;
  border-radius: 8px;
}

input::placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  letter-spacing: 0.3px;

  color: #4b506d;

  opacity: 0.5;
}

.SignInButton {
  background: #67c4a7;
  border-radius: 10px;

  box-shadow: 0px 4px 12px rgba(55, 81, 255, 0.24);

  border: none;
  height: 48px;
  width: 356px;
  color: white;
  margin-top: 20px;
  cursor: pointer;
}

input[type="checkbox"] {
  height: 20px;
  width: 20px;
}

.remember {
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 10px 0;
}
.LoginError {
  display: flex;
  align-items: center;
  gap: 15px;
  background-color: rgb(224, 80, 80);
  color: white;
  padding: 15px 25px;
  margin-bottom: 15px;
  border-radius: 5px;
}
.LoginLoading {
  animation: load 1500ms linear infinite;
}
@keyframes load {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.eye {
  position: absolute;
  bottom: 145px;
  right: 70px;
}
/* For Internet Explorer */
input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}

/* For Microsoft Edge */
input[type="password"]::-webkit-contacts-auto-fill-button,
input[type="password"]::-webkit-credentials-auto-fill-button,
input[type="password"]::-ms-reveal {
  display: none !important;
}
