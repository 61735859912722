.sidebar{
    width: 342px;;
    border-right: 1px solid grey;
    padding: 71px 21px 0 43px; 
    height:2340px;
  
}
.sidebarr{
    width:fit-content;
    border-right: 1px solid grey;
    padding: 71px 21px 0 43px; 
    height:1340px;
    
}
.PageItem{
    display: flex;
    align-items: center;
    gap: 20px;


}
.PageItems{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    padding: 10px ;
    border-radius: 24px 0 0 24px;
    
    
}
.sidebar > p {
    margin-bottom: 15px;
    margin-left: 10px;
}
.PageItems:hover{
    background-color: rgb(0, 0, 0,0.1);

    

}
.active{
    background-color: rgb(0, 0, 0,0.1);
 

}
.PageItem > p{
color: black;
}
.Link{
    text-decoration: none;
}
.DropdownContain{
    text-decoration: none;
    display: flex;
    background-color: #373846;
    color: white;
    flex-direction: column;
    gap: 15px;
    padding: 10px 15px;

}
.green{
    color: rgb(120, 168, 49);
    margin-right: 5px;
}
.DropdownContain > p:hover{
    color: rgb(120, 168, 49);

}
.Link > p:hover{
    color: rgb(120, 168, 49);

}


.Link > p{
    color: white;

}
.Hide{
    display: none;
    
}