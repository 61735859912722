.Review{
    position: absolute;
    left: 342px;
    top: 114px;
}
.contain{
    position: relative; 
}
.ReviewImg{
    display: flex;
    align-items: center;
    gap: 10px;

}

.ReviewImg > img {
    width: 85px;
    border-radius: 5px;
    height: 85px;
    

}
.ReviewContain{
    height: 1900px;
    border: 1px solid gray;
    margin: 20px;
}