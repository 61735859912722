.Customers{
    position: absolute;
    left: 342px;
    top: 114px;
}
.contain{
    position: relative; 
}
.CustomersContain{
    margin: 32px;
}
.CustomerTitle > p {
    background-color: gray;
    padding: 5px;
    border-radius: 5px;
}
.CustomerTitle{
    display: flex;
    gap:10px;
}

.CustomersPageBox{
border: 1px solid #ddd;
width: 1200px;
height: 1400px;
margin-top: 30px;
border-radius: 9px;

}
.CategoryPageSearch > input{
    border: none;
    outline: none;
    border-bottom: 1px solid gray;
    padding-bottom: 16px;
width: 200px;
}
.CategoryPageSearch > .bannerIcon {
    height: 33px;
   
}
.CategoryPageSearch > button {
    background-color: dodgerblue;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
}
.CategoryPageSearch {
    display: flex;
    align-items: center;
    margin: 20px 0 10px 10px;
}
.Eye{
color: dodgerblue;
height: 40px;
width: 40px;
padding: 10px;
border: 1px solid #ddd;
cursor: pointer;
}
.Rowcolor{
background-color: rgb(207, 255, 207);
color: rgb(16, 164, 0);
padding: 3px;
border-radius:7px ;
width: max-content;
}
.Rowcolor2{
    background-color: rgb(255, 173, 173);
color: rgb(255, 43, 43);
padding: 3px;
border-radius:7px ;
width: max-content;
}
.CustomerNumbers{
    display: flex;
    align-items: center;
  position: absolute;
    gap: 65px;
    bottom: 17px;
    left: 45px;

} 
.CustomerNumbers > p{
    cursor: pointer;
}



.CustomerPromt{
    position: absolute;
   top: 20%;
    left: 50%;
    transform: translate(-50%,-10%);
    background-color: #242424;
    z-index: 100;
    
    color: white;
    padding: 10px;
    border-radius: 10px;
    
}
.CustomerPromt > p {margin-bottom: 60px;}
.CustomerPromtButton{
    display: flex;
    justify-content: space-around ;
}
.CustomerPromtButton > button{
    border: 1px solid white;
    outline: none;
    padding: 8px 10px;
    background-color: transparent;
    color: white;
    border-radius: 9px;
    cursor: pointer;

}
