.Orders{
    position: absolute;
    left: 342px;
    top: 114px;
}
.contain{
    position: relative;
    
   
}
.count{
    display: flex;
    position: absolute;
    align-items: center;
    top: 52px;
    left: 47px;
    width: 500px;
  
  
}

.count > h2 {
   
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    
    color: #000000;
    
margin-right: 19px;


}
.count > p{
    background: #D9D9D9;
border-radius: 10px;
padding: 6px 11px;

}
.box{
    position: absolute;
    top: 154px;
    left: 48px;
    width:1200px;
height: 1400px;
border: 1px solid rgba(0, 0, 0, 0.58);
border-radius: 10px;


}
.OrderTable{
    position: relative;
    width: 1150px;
}
.search{
    margin-top: 48px;
    margin-left: 43px;
    display: flex;
    align-items: center;
}
.search > input {
    outline: none;
    border: none;
    border-bottom: 1px solid gray;
    padding: 0 0 15px 14px;
    
}
.icon{
    border-bottom: 1px solid gray;
    padding-bottom: 14px;
    

}
.search > button {
    margin-left:24px ;
    padding: 4px 12px;
    border-radius: 10px;
    border: 1px solid black;
    cursor: pointer;
    
}

.search {
    margin-bottom: 30px;
}




.OrderCheckBox{
   appearance: none;
   -webkit-appearance:none ;
 border: 1px solid gray;
  cursor: pointer;
  border-radius: 2px;
 
  height: 50px;
  width: 50px;
}
#check1{
border-radius: 6px  0 0 6px;

}
#check3{
border-radius: 0  6px 6px 0;

}
#check1:checked{
    background-color: orange;
  
}

#check2:checked{
    background-color: purple;
}
#check3:checked{
    background-color: green;
}
.box{
    height: 1600px;
   width: fit-content;
    
   }
@media (max-width:862px) {
   .box{
    height: 1850px;
    width: 700px;
    
   }
   
    
}
.orderLink{
    text-decoration: none;
    color: black;
    

}
.orderLink:hover{
  color: blue;
  text-decoration: underline;
}

.CustomerContainer > p:nth-child(2){
    background-color:#e5f3ff ;
   width: fit-content;

 padding:6px 6px 6px 0px;
 margin-top: 4px;
border-radius: 8px;
font-weight:700;
font-size: 13px;
}