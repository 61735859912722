.Category{
    position: absolute;
    left: 342px;
    top: 114px;
}
.contain{
    position: relative; 
}
.CategoryBox{
    border: 1px solid #dbdbdd;
    height: fit-content;
    width: 1100px;
    margin-bottom: 20px;
    position: relative;

    
}
.CategoryBoxTable{
    margin-bottom: 90px;
    height: 550px;
}
.CategoryContain{
    margin: 30px;
    
}
.CategoryContain > p{
    font-size: 34px;
    font-weight: 600;
    margin-bottom: 25px;
}
.CategoryBox > p{
  
  
  margin: 20px;
}
.CategoryInput{
    display: flex;
    flex-direction: column;
    position: relative;
}
.CategoryInput > input{
    padding: 15px 20px;
    border: 1px solid #ddd;
    outline: none;
}
.iconDownCategory{
    position: absolute;
    right: 25px;
    bottom: 15px;
}
.CategoryInput > select{
    padding: 15px 20px;
    border: 1px solid #ddd;
    outline: none;
    appearance: none;
    -webkit-appearance: none;
    
}
.CategoryInputs{
    display: flex;
    flex-direction: column;
    padding: 24px;
    gap: 20px;

}
.custom-file-wrp2{
	display: flex;
	width: 1050px;
    margin-top: 10px;
}
.CategoryButton{
    background-color: dodgerblue;
    color: white;
    padding: 15px 18px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
   
}
.CategoryImage{
    width: 400px;
    height: 200px;
    object-fit: contain;

margin: 0 auto;
}
.iconAction{
    border: 1px solid #ddd;
    
    cursor: pointer;
    width: 40px;
    height: 40px;
    padding: 8px;
    margin-right: 4px;
}

.Prioritary{
    width: 200px;
    padding: 5px;
}
.TableTitles{
    background-color: #d1d1d1;
}
.CategoryNumbers{
  margin-top: 60px;
    display: flex;
    gap: 70px;
   


    color: gray;
    align-items: center;
    
}
.dynamic{
    background-color: dodgerblue;
    padding: 5px 10px;
    border-radius: 4px;
    color: white;


}
.CategoryBoxTable{
    position: relative;
    height: 1400px;
    border: 1px solid gray;
}
@media (max-width:862px) {
    .CategoryBox{
        width: 500px;
    }
    .filename{
        width: 250px;
    }
    .CategoryBoxTable{
        width: 500px;
    }
}