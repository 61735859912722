.navbarContain > img {
    height: 85px;
    

   
    border-left: 1px solid gray;
   margin-right: 90px;
}
.navbarContain{
    display: flex;
    align-items: center;
}
.account > img {
    height: 45px;
    width: 45px;
    border-radius: 50%;
    object-fit: cover;
}
.navbar{
    display: flex;
    padding: 18px 94px 11px 106px;
    border-bottom: 1px solid gray;
align-items: center;
justify-content: space-between;

}
.navbarContain >p{
    margin-left: 50px;
    margin-right: 50px;
}
.account{
    background-color: #F3F4F6;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    width:250px;
    border-radius: 20px;
    gap: 15px;
    cursor: pointer;

}
.details{
    display: flex;
    flex-direction: column;
    align-items: center;
   
}
.AccountLogout{
    position: absolute;
    top: 100px;
    background-color: #242424;
    color: white;
    display: flex;
    align-items: center;
    padding: 15px 25px;
    gap: 10px;
    border-radius: 5px;
    cursor: pointer;
    z-index: 100;

}